
<script>
export default {
  name: "HeaderCategory",
  components: {},
  data() {
    return {
      enSite: false
    };
  },
  setup() {
    const { t, locale } = useI18n({
      useScope: "local",
    });
    const localePath = useLocalePath();
    return { t, locale: locale.value, localePath };
  },
  mounted() {
    this.injectCookieBannerScript();
  },
  methods: {
    injectCookieBannerScript() {
      if (!document.getElementById("cookieyes")) {
        const script = document.createElement("script");
        script.id = "cookieyes";
        script.type = "text/javascript";
        let cookiesSrc = this.locale === "en" ? useRuntimeConfig().public.cookieyes_en : useRuntimeConfig().public.cookieyes_fr
        script.src = `https://cdn-cookieyes.com/client_data/${cookiesSrc}/script.js`;
        document.head.appendChild(script);

        function findCookieYesElement(tag) {
          console.log("find", `[data-cky-tag="${tag}"]`)
          return document.querySelectorAll(`[data-cky-tag="${tag}"]`)[0]
        }
        
        function addButtonGTMEvent(button, userProperties) {
          if(!button) return;

          button.addEventListener("click", function() {
            window.dataLayer = window.dataLayer || [];
            console.log("cookie_accepted", userProperties);
            window.dataLayer.push({
              'event': 'cookie_banner_accepted', 
              'user_properties': userProperties
            });
          });
        } 
        
        function addGoogleTracking(tag, userProperties) {
          addButtonGTMEvent(findCookieYesElement(tag), userProperties)
          addButtonGTMEvent(findCookieYesElement(`detail-${tag}`), userProperties)
        }

        let retries = 0;

        const invertalId = setInterval(function() {
          const notice = findCookieYesElement("notice")
          if(retries++ > 50) clearInterval(invertalId)
          if(!notice) return
          
          clearInterval(invertalId)
          
          addGoogleTracking("accept-button", { 
            'cookies_accepted': true 
          })
          addGoogleTracking("reject-button", { 
            'cookies_accepted': false 
          })
        }, 500)
      }
    },
  },
};
</script>