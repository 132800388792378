<template>
  <div v-if="store?.state?.prevent_lang_change">
    <div
      @click="closeModal()"
      class="fixed top-0 left-0 w-full h-full bg-black/80 z-40"
    ></div>
    <div
      class="fixed bg-white rounded-lg top-1/2 left-1/2 w-11/12 md:w-1/2 -translate-x-1/2 -translate-y-1/2 z-50 pb-15"
    >
      <div class="text-right">
        <button @click="closeModal()">
          <icon-close class="m-5"></icon-close>
        </button>
      </div>
      <div class="max-w-455 mx-auto text-center">
        <h4 class="text-center mt-1 px-5">{{ t("title") }}</h4>
        <p class="text-center mt-4 px-5 font-bold">{{ t("description") }}</p>

        <div>
          <div class="text-center">
            <nuxt-link
              class="inline-block mt-5 h-11.5 py-[0.6875rem] items-center justify-center bg-blue text-base text-white rounded-br-none font-semibold w-226 hover:bg-black hover:text-white transition duration-300 rounded-t-lg rounded-bl-lg"
              :to="switchLocalePath('en', { name: 'quit' })"
            >
              {{ t("cta-1") }}
            </nuxt-link>
          </div>
        </div>
        <div>
          <div class="text-center">
            <button
              class="inline-block mt-5 h-11.5 py-[0.6875rem] items-center justify-center bg-blue text-base text-white rounded-br-none font-semibold w-226 hover:bg-black hover:text-white transition duration-300 rounded-t-lg rounded-bl-lg"
              @click="redirectFr()"
            >
              {{ t("cta-2") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IconClose from "~/assets/svg/close.svg";

export default {
  components: {
    IconClose,
  },
  setup() {
    const nuxtApp = useNuxtApp();
    const router = useRouter();
    const { t } = useI18n({
      useScope: "local",
    });
    const switchLocalePath = useSwitchLocalePath();

    let frRoute = switchLocalePath('fr', { name: router?.currentRoute.value?.name?.split("__")?.[0] })

    return { t, switchLocalePath, router, store: nuxtApp.$store, frRoute };
  },
  methods: {
    redirectFr() {
      let frenchUrl = this.fromNavigation() ? this.store.state.prevent_lang_change : this.frRoute
      navigateTo(frenchUrl, { external: true })
    },
    closeModal() {
      if (this.fromNavigation()) {
        this.store.commit('preventLangChange', false);
      } else {
        this.redirectFr();
      }
    },
    fromNavigation() {
      return typeof(this.store?.state?.prevent_lang_change) == "string"
    }
  }
};
</script>

<i18n lang="json">
{
  "fr": {
    "title": "This content is avalaible in French only.",
    "description": "J'ARRÊTE - I QUIT NOW, the online method for quitting smoking, is available in English.",
    "cta-1": "Go to I QUIT NOW",
    "cta-2": "See French content",
    "cta-3": "Return to : I Quit Now"
  },
  "en": {
    "title": "This content is avalaible in French only.",
    "description": "J'ARRÊTE - I QUIT NOW, the online method for quitting smoking, is available in English.",
    "cta-1": "Go to I QUIT NOW",
    "cta-2": "See French content",
    "cta-3": "Return to : I Quit Now"
  }
}
</i18n>

<style lang="scss" scoped>
.max-w-455 {
  max-width: 455px;
}

.w-226 {
  width: 226px;
}
</style>
