<template>
  <footer class="pb-px rounded-tr-[32px] bg-[#2d334a] text-white max-smallTablet:[&.-footer-hidden]:!hidden">
    <div class="tablet:mb-0 px-3 bg-black text-[14px] sm:text-[12px] md:text-[14px] font-inter">
      <ul class="gap-12 *:shrink-0 *:my-auto *:py-3">
        <div class="inline-block sm:flex justify-center w-full !pb-1 !pt-2">  
          <li class="flex sm:inline-block py-2 sm:px-2 sm:py-2 justify-center">
            <FallbackLink :to="{ name: 'responsability' }">{{ t("responsibility") }}</FallbackLink>
          </li>
          <li class="flex sm:inline-block py-2 sm:px-2 sm:py-2 justify-center">
            <FallbackLink :to="{ name: 'privacy-policy' }">{{ t("privacy-policy") }}</FallbackLink>
          </li>
          <li class="flex sm:inline-block py-2 sm:px-2 sm:py-2 justify-center">
            <FallbackLink :to="{ name: 'personal-information-policy' }">{{ t("personal-information-policy") }}</FallbackLink>
          </li>
        <li class="flex sm:flow py-2 sm:px-2 sm:py-2 justify-center">
            <button class="flex cky-banner-element pr-5">{{ t("cookies-settings") }}</button>
            <FallbackLink :to="{ name: 'sitemap' }">{{ t("sitemap") }}</FallbackLink>
          </li>
          <li class="flex sm:flow py-2 sm:px-2 sm:py-2 justify-center gap-2">
            <a class="-ml-2 px-2" target="_blank" href="https://www.facebook.com/quebecsanstabac/">
              <img alt="Facebook" class="inline-block w-6 h-6" src="~/assets/svg/fb2.svg?inline" />
            </a>
            <a class="-mr-2 px-2" target="_blank" href="https://www.instagram.com/quebec_sans_tabac/">
              <img alt="Instagram" class="inline-block w-6 h-6" src="~/assets/svg/instagram.svg?inline" />
            </a>
          </li>
        </div>
        <li class="flex justify-center !pt-1 !pb-5">
          <a href="https://cqts.qc.ca/" target="_blank">&copy;{{ t("tips") }}</a>
        </li>
      </ul>
      <div class="lg-footer:w-auto h-px bg-white/10 lg-footer:hidden"></div>
    </div>
  </footer>
</template>

<script>
import FallbackLink from "~/components/FallbackLink.vue";

export default {
  components: {
    FallbackLink,
  },
  setup() {
    const { t } = useI18n({
      useScope: "local",
    });
    return { t };
  },
};
</script>

<i18n lang="json">
{
  "fr": {
    "follow-us": "Suivez Québec sans tabac",
    "responsibility": "Conditions d'utilisation",
    "privacy-policy": "Politique de confidentialité",
    "personal-information-policy": "Politique des renseignements personnels",
    "cookies-settings": "Paramètres des témoins",
    "logo": "Logo",
    "tips": "Conseil québécois sur le tabac et la santé",
    "sitemap": "Plan du site",
    "leeroy-alt": "LEEROY Agence Créative Digitale - Web & Marketing"
  },
  "en": {
    "follow-us": "Follow Québec sans tabac",
    "responsibility": "Terms of Use",
    "privacy-policy": "Privacy Policy",
    "personal-information-policy": "Personal Information Policy",
    "cookies-settings": "Cookies Settings",
    "logo": "Logo",
    "tips": "Quebec Council on Tobacco and Health",
    "sitemap": "Sitemap",
    "leeroy-alt": "LEEROY Creative Agency -  Digital, Web & Marketing"
  }
}
</i18n>
