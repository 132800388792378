export default {
  setCanonical(locale) {
    const config = useRuntimeConfig();
    const router = useRouter();
    const otherLocale = locale == "fr" ? "en" : "fr";
    let links = [];

    if (useRequestURL().searchParams.get('path')?.endsWith('png')) { return }

    if (router.currentRoute.value.name) {
      let otherLocaleRoute = router
        .getRoutes()
        .find((route) => route.name == router.currentRoute.value.name.replace(`_${locale}`, `_${otherLocale}`));
      if (otherLocaleRoute?.path) {
        links = [
          {
            rel: "canonical",
            href: config.public[`${locale}Domain`] + router.currentRoute.value.fullPath,
          },
          {
            rel: "alternate",
            href: config.public[`${otherLocale}Domain`] + otherLocaleRoute?.path.split("subpage/:")[0],
            lang: otherLocale,
          },
          {
            rel: "alternate",
            href: config.public[`${locale}Domain`] + router.currentRoute.value.fullPath,
            lang: locale,
          },
        ];
      } else {
        links = [
          {
            rel: "canonical",
            href: config.public.frDomain + router.currentRoute.value.fullPath,
          },
        ];
      }
    }

    useHead({
      link: links,
    });
  },
};
