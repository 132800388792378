<template>
  <nuxt-link v-if="localePath(to)" :to="localePath(to)">
    <slot />
  </nuxt-link>
  <nuxt-link v-else :to="switchLocalePath('fr', to)" @click.exact.prevent="preventLangChange($event)">
    <slot />
  </nuxt-link>
</template>

<script>
export default {
  name: "FallbackLink",
  props: {
    to: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const nuxtApp = useNuxtApp();
    return { nuxtApp };
  },
  methods: {
    preventLangChange(event) {
      this.nuxtApp.$store.commit("preventLangChange", event.currentTarget.href);
    },
  }
};
</script>
